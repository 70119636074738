<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="mt-1">
    <b-card>
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" v-if="departmentId > 0">Department Edit</th>
              <th scope="col" v-else>Department Add</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Department" label-for="title">
              <b-form-input required id="title" type="text" v-model="departmentValue.title"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col cols="12">
            <b-button v-if="departmentId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
              Save
            </b-button>
            <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>
<script>
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import AppColorPaletteVue from '@/@core/layouts/components/AppColorPalette.vue';

export default {
  components: {
    vSelect,
    ToastificationContent,
    AppColorPaletteVue,
  },

  setup() {
    const DEPARTMENT_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(DEPARTMENT_APP_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      departmentValue: {
        title: '',
      },

      loading: false,
      departmentId: null,
    };
  },

  watch: {},

  mounted() {
    this.getDepartmentById();
  },

  methods: {
    getDepartmentById() {
      this.loading = true;

      if (router.currentRoute.params.id) {
        this.departmentId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('settings/getDepartmentById', { id: router.currentRoute.params.id })
          .then((res) => {
            this.departmentValue = res.data;

            this.loading = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.loading = false;
      }
    },

    formSubmitted() {
      this.loading = true;
      if (!this.departmentValue.title) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please fill in all fields.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        this.loading = false;
        return;
      }

      if (this.departmentId == null) {
        store
          .dispatch('settings/saveDepartment', this.departmentValue)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Department Add Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'settings-departments' });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        store
          .dispatch('settings/updateDepartment', this.departmentValue)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Department Update Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'settings-departments' });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
